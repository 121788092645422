
import { defineComponent, ref, computed } from 'vue'

import AsTechQuestion from '@web/components/AsTechQuestion.vue'
import AsModal from '@web/components/AsModal.vue'

import Time from '@web/common/Time'

import useMobileAccess from '@web/composition/useMobileAccess'
import { useStore } from '@web/store'
import { IS_MOBILE } from '@web/common/config'
import Analytics from '@web/services/Analytics/Analytics'
import { useRouter } from 'vue-router'
import StreamStatus from '@web/consts/StreamStatus'
import i18n from '@web/plugins/i18n'
import AsAvatar from '@web/components/AsAvatar.vue'

export default defineComponent({
  components: {
    AsAvatar,
    AsTechQuestion,
    AsModal
  },
  props: {
    rooms: {
      type: Array,
      default: () => []
    },
    currentId: {
      type: Number,
      default: () => 0
    },
    type: {
      type: String,
      default: () => 'chat'
    },
    showSupport: {
      type: Boolean,
      default: () => true
    }
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const mobileAccess = useMobileAccess()

    const modal = ref(false)

    const techSupport = computed(() => store.state.assets.techSupport)

    function formatDate (date) {
      return Time(date).fromNow()
    }
    function hasUnviewedMessages (streamId) {
      const channelMeta = store.getters['stream/getChatChannelMetaByStreamId'](streamId)
      return channelMeta ? !!channelMeta.unviewedUserMessage : false
    }

    function goToChat ({ to, streamId }) {
      const stream = store.getters['stream/getStreamById'](streamId)

      if (IS_MOBILE && !stream?.mobileAccess) {
        mobileAccess.showModal()
      } else {
        router.push(to)
      }
    }

    function goToSupport () {
      Analytics.send({
        category: 'support_activity',
        action: 'open_support',
        label: 'chats'
      })
      router.push({ name: 'support' })
    }

    function getTimeSubtext (room) {
      if (room.streamStatus === StreamStatus.ENDED || room.streamStatus === StreamStatus.STOPPED) {
        return `<div class="-notAvailable">${i18n.global.t('courseEnd')}. ${i18n.global.t('chatNotAvailable')}</div>`
      }
      return formatDate(room.time)
    }

    return {
      techSupport,
      modal,

      hasUnviewedMessages,
      formatDate,
      goToChat,
      goToSupport,
      getTimeSubtext
    }
  }
})
