import Swal from 'sweetalert2'
import { saleEmail } from '@web/common/ExternalConfig/getters'
import i18n from '@web/plugins/i18n'

function useMobileAccess () {
  function showModal () {
    const email = saleEmail()
    Swal.fire({
      html: `${i18n.global.t('mobileAccessDenied')} <a href="mailto:${email}">${email}</a>`,
      icon: 'error'
    })
  }

  return {
    showModal
  }
}

export default useMobileAccess
