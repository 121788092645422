import { useStore } from '@web/store'
import { computed, ref } from 'vue'
import axios, { CancelTokenSource } from 'axios'
import { MessageSend } from '@web/store/types/modules/chat'
import Swal from 'sweetalert2'
import * as AnalyticEvents from '@web/services/Analytics/events'
import Logger from '@web/common/Logger'
import FileApi from '@web/api/modules/File'
import isEmpty from 'lodash.isempty'
import { v4 as uuidv4 } from 'uuid'
import { messageDelay } from '@web/common/ExternalConfig/getters'
import i18n from '@web/plugins/i18n'
import { IS_WEB } from '@web/common/config'

type mfFile = {
  id: number;
  name: string;
  percent: number;
  status: string;
  cancelToken: CancelTokenSource | null;
}

export default function useMessageForm ({ chatId }: { chatId: number }) {
  const store = useStore()

  let messageDelayFinished = true
  const message = ref('')
  const previews = ref<(string | ArrayBuffer | null)[]>([])
  const files = ref<Dictionary<mfFile>>({})
  const sending = ref(false)
  const loading = ref(false)
  const error = ref('')
  const messageMaxLength = 1500

  const user = computed(() => store.getters['user/user'])
  const isFilesLoading = computed(() => Object.values(files.value).some(file => file.status === 'loading'))

  function createMessage () {
    const _message: MessageSend = {
      message: message.value,
      userId: user.value.id,
      dialogId: chatId
    }

    /**
     * В мобильном хроме не срабатывает проверка isEmpty, потому что files=['None']
     * Sentry /issues/33901/
     */
    const _files = Object.values(files.value).map(({ id }) => id)
    if (Array.isArray(_files) && _files.length > 0 && String(_files[0]) !== 'None') {
      _message.files = _files.filter(file => file)
    }
    return _message
  }

  function send () {
    if (!messageDelayFinished) {
      Swal.fire({
        title: `${i18n.global.t('attention')}!`,
        text: i18n.global.t('error.messageDelay').replace('$seconds', messageDelay().toString()),
        icon: 'error'
      })
      return
    }

    error.value = ''

    if (isFilesLoading.value) {
      Swal.fire({
        title: i18n.global.t('Error'),
        text: i18n.global.t('error.file'),
        icon: 'error'
      })
      return
    }

    if (!isEmpty(files.value) && !message.value) {
      error.value = i18n.global.t('error.requireMessage')
    }

    if (!message.value || sending.value) return

    sending.value = true
    messageDelayFinished = false

    const _message = createMessage()

    store.dispatch('chat/sendMessage', {
      id: chatId,
      message: _message
    })
      .then(() => {
        message.value = ''
        files.value = {}

        AnalyticEvents.messageSend()
      })
      .catch((e) => {
        let message = i18n.global.t('error.cantSendMessage')
        if (e && e.response && Array.isArray(e.response.data)) {
          message = e.response.data[0].message
        }
        Swal.fire({
          title: i18n.global.t('Error'),
          text: message,
          icon: 'error'
        })
        Logger.error(e)
      })
      .finally(() => {
        sending.value = false
        setTimeout(() => {
          messageDelayFinished = true
        }, messageDelay() * 1000)
      })
  }

  function addFile (localId, { id, name, percent, status, cancelToken }: mfFile) {
    if (!localId) { return }

    files.value = { ...files.value, [localId]: {} }

    files.value[localId] = {
      id,
      name,
      percent,
      status,
      cancelToken
    }
  }

  async function fileUpload (file: File) {
    const id = uuidv4()

    loading.value = true
    try {
      if (!file.type.match(/image|video/)) {
        Swal.fire({
          title: i18n.global.t('Error'),
          text: i18n.global.t('error.chatFileTypeUpload'),
          icon: 'error'
        })
        return
      }

      const maxFileSizeMb = mb => mb * 1024 * 1024
      if (file.size > maxFileSizeMb(1024)) {
        Swal.fire({
          title: i18n.global.t('Error'),
          text: i18n.global.t('error.chatFileSizeLimit'),
          icon: 'error'
        })
        return
      }

      addFile(id, {
        id: 0,
        name: file.name,
        percent: 0,
        status: 'loading',
        cancelToken: axios.CancelToken.source()
      })

      if (IS_WEB) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.addEventListener('load', () => {
          previews.value.push(reader.result)
        }, false)
      }

      const { data: fileData } = await FileApi.uploadWeb(file, {
        cancelToken: files.value[id].cancelToken?.token,
        onUploadProgress: progressEvent => {
          if (files.value[id]) {
            files.value[id].percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
          }
        }
      })

      files.value[id].id = fileData.id
      files.value[id].percent = 100
      files.value[id].status = 'loaded'
      files.value[id].cancelToken = null
    } catch (error) {
      Logger.log(error)

      // ошибка загрузки файла может произойти после того
      // как пользователь перешёл на другую страницу
      // или как пользователь отменил загрузку файл
      if (!files.value[id]) {
        return
      }
      if (!axios.isCancel(error)) {
        files.value[id].status = 'error'
      }
      Swal.fire({
        title: i18n.global.t('Error'),
        text: `${i18n.global.t('error.uploadFile')} ${file.name}`,
        icon: 'error'
      })
    } finally {
      loading.value = false
    }
  }

  function fileUploadWeb (e: Event) {
    const target = e.target as HTMLInputElement
    const file = target.files && target.files[0]
    if (file) {
      fileUpload(file)
      target.value = ''
    }
  }

  function remove (id: number) {
    if (files.value[id].cancelToken) {
      files.value[id].cancelToken?.cancel()
      files.value[id].cancelToken = null
    }

    delete files.value[id]
    files.value = { ...files.value }
  }

  function msg (str: string) {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
  }

  return {
    files,
    error,
    message,
    sending,
    messageMaxLength,

    remove,
    send,
    fileUploadWeb,
    msg
  }
}
