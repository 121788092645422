import { ref, computed, onBeforeUnmount } from 'vue'
import { useStore } from '@web/store'
import { ChatMessage, getMessages } from '@web/common/messages'
import { Chat } from '@web/store/types/modules/chat'
import { IS_MOBILE } from '@web/common/config'
import i18n from '@web/plugins/i18n'
import Analytics from '@web/services/Analytics/Analytics'
import { Router } from 'vue-router'

function useChat ({ router }: { router: Router }) {
  const store = useStore()

  const loaded = ref(false)
  const loadedChat = ref(true)
  const showError = ref(false)
  const showChatForm = ref(true)

  const loadedRequiredParameters = computed(() => store.getters['stream/isLoadedAll'] && store.getters['subscription/isLoaded'])

  const menu = computed(() => store.getters['menu/general'])
  const chats = computed(() => store.getters['chat/availableChats'])
  const showChatContent = computed(() => Boolean(chats.value.length))
  const isPaidConsultationsOverByStreamId = computed(() => store.getters['stream/isPaidConsultationsOverByStreamId'])
  const title = computed(() => i18n.global.t('messages'))
  const chatId = computed(() => {
    let id = String(router.currentRoute.value.params.chatId || '')
    if (!IS_MOBILE) {
      id = id || String(chats.value[0]?.id || '')
    }
    return parseInt(id, 10)
  })
  const homeworkStatusId = computed<number>(() => Number(router.currentRoute.value.params.homeworkStatusId))
  const homework = computed(() => homeworkStatusId.value ? store.getters['homework/getHomeworkByStatusId'](homeworkStatusId.value) : undefined)
  const currentRoom = computed<Chat | undefined>(() => {
    let chat

    if (router.currentRoute.value.params.chatId) {
      chat = chats.value.find(room => room.id.toString() === chatId.value.toString())
    }

    if (!chat && !IS_MOBILE) {
      const chats = store.getters['chat/availableChats']
      chat = chats && chats[0]
    }

    return chat || undefined
  })

  const chatTitle = computed<string>(() => (currentRoom.value && currentRoom.value.title) || '')
  const courseId = computed<number | undefined>(() => currentRoom.value && currentRoom.value.courseId)
  const streamId = computed<number | undefined>(() => currentRoom.value && currentRoom.value.streamId)

  function checkChatId () {
    if (router.currentRoute.value.name === 'chat') {
      const chat = chats.value.find(c => c.id === chatId.value)
      if (!chat) {
        router.push({ name: 'chats' })
      }
    }
  }

  function setPageData (payload: {
    userCourseStreamId: number;
    streamId: number;
    courseId: number;
    dialogId: number;
    courseTitle: string;
  }) {
    Analytics.setPageData({
      userCourseStreamId: payload.userCourseStreamId.toString(),
      streamId: payload.streamId.toString(),
      courseId: payload.courseId.toString(),
      dialogId: payload.dialogId.toString(),
      courseTitle: payload.courseTitle
    })
  }

  onBeforeUnmount(() => {
    Analytics.clearPageData()
  })

  const messages = computed<ChatMessage[]>(() => {
    if (!currentRoom.value) {
      return []
    }
    const course = store.getters['course/getCourseById'](currentRoom.value.courseId)
    const stream = store.getters['stream/getStreamById'](currentRoom.value.streamId)
    if (currentRoom.value.messages && course && stream) {
      return getMessages({
        messages: [...currentRoom.value.messages],
        chatId: chatId.value,
        course,
        stream,
        user: store.getters['user/user'],
        username: store.getters['user/username']
      })
    } else {
      return []
    }
  })

  return {
    loaded,
    loadedChat,
    loadedRequiredParameters,
    showError,
    showChatForm,
    menu,
    chats,
    showChatContent,
    isPaidConsultationsOverByStreamId,
    title,
    currentRoom,
    chatTitle,
    courseId,
    streamId,
    chatId,
    checkChatId,
    setPageData,
    messages,
    homework,
    homeworkStatusId
  }
}

export default useChat
