
import { defineComponent, computed } from 'vue'

import isEmpty from 'lodash.isempty'
import { useStore } from '@web/store'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: () => ''
    },
    showMenu: {
      type: Boolean,
      default: () => true
    },
    prev: {
      type: Function,
      default: null
    }
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const history = computed(() => store.getters['router/history'])

    function goBack () {
      if (['chat', 'chats'].includes(String(route.name) || '')) {
        if (window.innerWidth <= 828) {
          if (route.name === 'chat') {
            return router.push({ name: 'chats' })
          }
          if (route.name === 'chats') {
            return router.push({ name: 'userSetting' })
          }
        }
        const lastRouteNotChat = history.value.find(route => !['chat', 'chats', 'userSetting'].includes(route.name)) || { name: 'courses' }
        return router.push(lastRouteNotChat)
      }
      if (props.prev) {
        props.prev()
        return
      }
      return isEmpty(history) ? router.push({ name: 'courses' }) : router.go(-1)
    }

    return {
      goBack
    }
  }
})
