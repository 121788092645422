<template>
  <div class="asChatNote"><slot /></div>
</template>

<style lang="scss">
  @import '@web/assets/scss/vars.scss';

  .asChatNote {
    padding: 8px 16px;

    color: rgb(136, 152, 177);
    font-size: 16px;
    line-height: 20px;

    @media (max-width: $bp-tablet) {
      background-color: white;
      box-shadow: 0 10px 25px rgba(23, 50, 104, 0.27);
      z-index: 2;
      border-top: 1px solid rgb(226, 232, 237);
    }

    &.-title {
      padding-top: 0;
      padding-bottom: 0;
    }

    &:empty {
      display: none;
    }
  }
</style>
