
import { defineComponent, computed, ref, onMounted } from 'vue'
import useMessageForm from '@web/composition/useMessageForm'

export default defineComponent({
  props: {
    chatId: {
      type: Number,
      required: true
    },
    fixed: {
      type: Boolean,
      default: () => false
    },
    autofocus: {
      type: Boolean,
      default: () => false
    },
    autoResize: {
      type: Boolean,
      default: false
    },
    maxRows: {
      type: Number,
      default: 4
    },
    fullHeight: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const refTextareaWrap = ref<HTMLDivElement | null>(null)
    const refTextarea = ref<HTMLTextAreaElement | null>(null)

    const { message, messageMaxLength, ...data } = useMessageForm({
      chatId: props.chatId
    })

    function autoResize () {
      if (props.autoResize && refTextarea.value && refTextareaWrap.value) {
        const styles = window.getComputedStyle(refTextarea.value, null)
        const _minHeight = parseFloat(styles.getPropertyValue('min-height'))
        let _maxHeight = parseFloat(styles.getPropertyValue('max-height'))
        const _lineHeight = parseFloat(styles.getPropertyValue('line-height'))

        if (!_maxHeight) {
          _maxHeight = props.maxRows * _lineHeight + 1
          refTextarea.value.style.maxHeight = `${_maxHeight}px`
        }

        let _height = refTextarea.value.scrollHeight || (refTextarea.value.value.split(/[\n\r]/).length * _lineHeight + refTextarea.value.value.split(/[\n\r]/).length)
        _height = Math.max(_minHeight, _height)
        _height = Math.min(_maxHeight, _height)
        refTextareaWrap.value.style.height = `${_height}px`
      }
    }

    function setMaxHeight () {
      if (refTextarea.value) {
        const styles = window.getComputedStyle(refTextarea.value, null)
        const _lineHeight = parseFloat(styles.getPropertyValue('line-height'))

        if (props.autoResize) {
          const _maxHeight = props.maxRows * _lineHeight + 1
          refTextarea.value.style.maxHeight = `${_maxHeight}px`
        }

        if (props.fullHeight) {
          refTextarea.value.style.height = `${props.maxRows * _lineHeight}px`
        }
      }
    }

    const messageModel = computed({
      get () {
        return message.value
      },
      set (newMessage: string) {
        message.value = newMessage.slice(0, messageMaxLength)
        autoResize()
      }
    })

    function handlerFocusTextarea (event) {
      setTimeout(() => {
        event.target.focus()
        event.target.scrollIntoViewIfNeeded()
      }, 300)
    }

    onMounted(() => {
      setMaxHeight()
      autoResize()
    })

    return {
      ...data,
      refTextareaWrap,
      refTextarea,
      message,
      messageMaxLength,
      messageModel,

      handlerFocusTextarea
    }
  }
})
