import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "asNotAvailablePage" }
const _hoisted_2 = { class: "asNotAvailablePage_title" }
const _hoisted_3 = { class: "asNotAvailablePage_text" }
const _hoisted_4 = { class: "asNotAvailablePage_actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "back-content"),
    _createElementVNode("div", {
      class: _normalizeClass(["asNotAvailablePage_wrapper", `-${_ctx.as}`])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "title", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.$t('notAvailablePage.enteredSection')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "text", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.$t('notAvailablePage.notIncluded')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "actions", {}, () => [
          _createElementVNode("div", {
            class: "asNotAvailablePage_btn",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
          }, [
            _renderSlot(_ctx.$slots, "btn_goBack", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.$t('returnBack')), 1)
            ])
          ])
        ])
      ])
    ], 2)
  ]))
}