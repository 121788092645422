import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23dabff0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lCommon" }
const _hoisted_2 = { class: "lCommon_chatHeaderFixed" }
const _hoisted_3 = {
  key: 0,
  class: "lCommon_row"
}
const _hoisted_4 = { class: "chat-title-wrapper" }
const _hoisted_5 = { class: "asHeaderChat" }
const _hoisted_6 = { class: "asHeaderChat_title" }
const _hoisted_7 = { class: "lCommon_row" }
const _hoisted_8 = { class: "chat-title-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_as_chat_rooms = _resolveComponent("as-chat-rooms")!
  const _component_as_preloader = _resolveComponent("as-preloader")!
  const _component_as_empty = _resolveComponent("as-empty")!
  const _component_as_not_available_page = _resolveComponent("as-not-available-page")!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_chat_consultation_note = _resolveComponent("as-chat-consultation-note")!
  const _component_as_chat_note = _resolveComponent("as-chat-note")!
  const _component_as_chat = _resolveComponent("as-chat")!
  const _component_as_renew_course = _resolveComponent("as-renew-course")!
  const _component_as_renew_consultations = _resolveComponent("as-renew-consultations")!
  const _component_as_chat_form = _resolveComponent("as-chat-form")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_header_chat = _resolveComponent("as-header-chat")!
  const _component_as_title = _resolveComponent("as-title")!
  const _component_as_row = _resolveComponent("as-row")!
  const _component_as_skeleton = _resolveComponent("as-skeleton")!
  const _component_as_rooms_skeleton = _resolveComponent("as-rooms-skeleton")!
  const _component_as_chat_skeleton = _resolveComponent("as-chat-skeleton")!
  const _component_as_spacer = _resolveComponent("as-spacer")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.name === 'chats')
      ? (_openBlock(), _createBlock(_component_mq_responsive, {
          key: 0,
          class: "lCommon",
          target: "mobile-tablet"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_as_header_mobile, {
              title: _ctx.$t('messages'),
              prevPage: _ctx.prevPage,
              back: "",
              "onMenu:toggle": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenMenu = $event))
            }, null, 8, ["title", "prevPage"]),
            (!_ctx.isNotAvailableChats)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.allLoaded && _ctx.showChatContent)
                    ? _withDirectives((_openBlock(), _createBlock(_component_as_chat_rooms, {
                        key: 0,
                        rooms: _ctx.rooms,
                        currentId: _ctx.chatId
                      }, null, 8, ["rooms", "currentId"])), [
                        [_vShow, !_ctx.isOpenMenu]
                      ])
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (!_ctx.allLoaded && !_ctx.showChatContent)
                          ? _withDirectives((_openBlock(), _createBlock(_component_as_preloader, { key: 0 }, null, 512)), [
                              [_vShow, !_ctx.isOpenMenu]
                            ])
                          : _withDirectives((_openBlock(), _createBlock(_component_as_empty, { key: 1 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('emptyChats')), 1)
                              ]),
                              _: 1
                            }, 512)), [
                              [_vShow, !_ctx.isOpenMenu]
                            ])
                      ], 64))
                ], 64))
              : _withDirectives((_openBlock(), _createBlock(_component_as_not_available_page, { key: 1 }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('notAvailablePage.enteredChat')), 1)
                  ]),
                  text: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('notAvailablePage.notIncludedChat')), 1)
                  ]),
                  _: 1
                }, 512)), [
                  [_vShow, !_ctx.isOpenMenu]
                ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.$route.name === 'chat')
      ? (_openBlock(), _createBlock(_component_mq_responsive, {
          key: 1,
          class: "lCommon -chat",
          target: "mobile-tablet"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_as_header_mobile, {
                title: `${_ctx.$t('experts')} ${_ctx.chatTitle}`,
                back: "",
                "onMenu:toggle": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpenMenu = $event))
              }, null, 8, ["title"])
            ]),
            (!_ctx.isNotAvailableChats)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _withDirectives(_createVNode(_component_as_chat_note, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_as_chat_consultation_note, { stream: _ctx.stream }, null, 8, ["stream"])
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, !_ctx.isOpenMenu]
                  ]),
                  (_ctx.showChatContent)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_ctx.stream && _ctx.course && _ctx.streamId)
                          ? _withDirectives((_openBlock(), _createBlock(_component_as_chat, {
                              key: 0,
                              stream: _ctx.stream,
                              course: _ctx.course,
                              "stream-id": _ctx.streamId
                            }, null, 8, ["stream", "course", "stream-id"])), [
                              [_vShow, !_ctx.isOpenMenu]
                            ])
                          : _withDirectives((_openBlock(), _createBlock(_component_as_preloader, { key: 1 }, null, 512)), [
                              [_vShow, !_ctx.isOpenMenu]
                            ])
                      ], 64))
                    : _withDirectives((_openBlock(), _createBlock(_component_as_empty, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('emptyChats')), 1)
                        ]),
                        _: 1
                      }, 512)), [
                        [_vShow, !_ctx.isOpenMenu]
                      ]),
                  (_ctx.stream)
                    ? _withDirectives((_openBlock(), _createBlock(_component_as_renew_course, {
                        key: `asRenewCourse_${_ctx.streamId}`,
                        stream: _ctx.stream,
                        class: "-chat"
                      }, null, 8, ["stream"])), [
                        [_vShow, !_ctx.isOpenMenu]
                      ])
                    : _createCommentVNode("", true),
                  (_ctx.stream)
                    ? _withDirectives((_openBlock(), _createBlock(_component_as_renew_consultations, {
                        key: `asRenewConsultations_${_ctx.streamId}`,
                        stream: _ctx.stream,
                        class: "-chat"
                      }, null, 8, ["stream"])), [
                        [_vShow, !_ctx.isOpenMenu]
                      ])
                    : _createCommentVNode("", true),
                  (_ctx.isShowChatForm)
                    ? _withDirectives((_openBlock(), _createBlock(_component_as_chat_form, {
                        key: `asChatForm_${_ctx.streamId}`,
                        id: _ctx.chatId,
                        courseId: _ctx.courseId,
                        chatId: _ctx.chatId,
                        autofocus: true,
                        class: "-fixed",
                        "auto-resize": ""
                      }, null, 8, ["id", "courseId", "chatId"])), [
                        [_vShow, !_ctx.isOpenMenu]
                      ])
                    : _createCommentVNode("", true)
                ], 64))
              : _withDirectives((_openBlock(), _createBlock(_component_as_not_available_page, { key: 1 }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('notAvailablePage.enteredChat')), 1)
                  ]),
                  text: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('notAvailablePage.notIncludedChat')), 1)
                  ]),
                  _: 1
                }, 512)), [
                  [_vShow, !_ctx.isOpenMenu]
                ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_mq_responsive, {
      class: "lCommon bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, null, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb, { title: _ctx.chatTitle }, null, 8, ["title"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_row, { class: "chat" }, _createSlots({
          menu: _withCtx(() => [
            _createVNode(_component_as_menu, {
              mini: true,
              menu: _ctx.menu,
              type: "sidebar"
            }, null, 8, ["menu"])
          ]),
          _: 2
        }, [
          (_ctx.allLoaded)
            ? {
                name: "content",
                fn: _withCtx(() => [
                  (!_ctx.isNotAvailableChats)
                    ? (_openBlock(), _createBlock(_component_as_row, {
                        key: 0,
                        class: "-inner -chat chatContent"
                      }, {
                        sidebar: _withCtx(() => [
                          _createVNode(_component_as_header_chat, {
                            title: _ctx.$t('messages')
                          }, null, 8, ["title"]),
                          _createVNode(_component_as_chat_rooms, {
                            rooms: _ctx.rooms,
                            currentId: _ctx.chatId
                          }, null, 8, ["rooms", "currentId"])
                        ]),
                        content: _withCtx(() => [
                          (_ctx.showChatContent)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _createElementVNode("div", _hoisted_4, [
                                  _createVNode(_component_as_title, {
                                    "data-test": "chat-title",
                                    class: "chat-title -withoutShadow"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('experts') + ' ' + _ctx.chatTitle), 1)
                                    ]),
                                    _: 1
                                  }),
                                  (_openBlock(), _createBlock(_component_as_chat_consultation_note, {
                                    key: `asChatConsultationNote_${_ctx.streamId}`,
                                    stream: _ctx.stream
                                  }, null, 8, ["stream"]))
                                ]),
                                (_ctx.stream && _ctx.course && _ctx.streamId)
                                  ? (_openBlock(), _createBlock(_component_as_chat, {
                                      key: `asChat_${_ctx.streamId}`,
                                      stream: _ctx.stream,
                                      course: _ctx.course,
                                      "stream-id": _ctx.streamId
                                    }, null, 8, ["stream", "course", "stream-id"]))
                                  : (_openBlock(), _createBlock(_component_as_preloader, { key: 1 })),
                                (_ctx.stream)
                                  ? (_openBlock(), _createBlock(_component_as_renew_course, {
                                      key: `asRenewCourse_${_ctx.streamId}`,
                                      stream: _ctx.stream,
                                      class: "-chat"
                                    }, null, 8, ["stream"]))
                                  : _createCommentVNode("", true),
                                (_ctx.stream)
                                  ? (_openBlock(), _createBlock(_component_as_renew_consultations, {
                                      key: `asRenewConsultations_${_ctx.streamId}`,
                                      stream: _ctx.stream,
                                      class: "-chat"
                                    }, null, 8, ["stream"]))
                                  : _createCommentVNode("", true),
                                (_ctx.isShowChatForm)
                                  ? (_openBlock(), _createBlock(_component_as_chat_form, {
                                      key: `asChatForm_${_ctx.streamId}`,
                                      id: _ctx.chatId,
                                      courseId: _ctx.courseId,
                                      chatId: _ctx.chatId,
                                      autofocus: true
                                    }, null, 8, ["id", "courseId", "chatId"]))
                                  : _createCommentVNode("", true)
                              ]))
                            : (_openBlock(), _createBlock(_component_as_empty, { key: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('emptyChats')), 1)
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_as_not_available_page, {
                        key: 1,
                        as: "modal"
                      }, {
                        "back-content": _withCtx(() => [
                          _createVNode(_component_as_row, { class: "-inner -chat chatContent" }, {
                            sidebar: _withCtx(() => [
                              _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("span", _hoisted_6, [
                                  _createVNode(_component_as_skeleton, {
                                    class: "asMessageSkeleton_title",
                                    style: {"width":"50%"}
                                  })
                                ])
                              ]),
                              _createVNode(_component_as_rooms_skeleton)
                            ]),
                            content: _withCtx(() => [
                              _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("div", _hoisted_8, [
                                  _createVNode(_component_as_skeleton, {
                                    class: "asMessageSkeleton_title",
                                    style: {"width":"50%"}
                                  }),
                                  _createVNode(_component_as_skeleton, { class: "asMessageSkeleton_subTitle" })
                                ]),
                                _createVNode(_component_as_chat_skeleton)
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        title: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('notAvailablePage.enteredChat')), 1)
                        ]),
                        text: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('notAvailablePage.notIncludedChat')), 1)
                        ]),
                        _: 1
                      }))
                ]),
                key: "0"
              }
            : {
                name: "content",
                fn: _withCtx(() => [
                  _createVNode(_component_as_preloader, { color: "white" })
                ]),
                key: "1"
              }
        ]), 1024),
        _createVNode(_component_as_spacer),
        _createVNode(_component_as_footer)
      ]),
      _: 1
    })
  ]))
}