
import { computed, defineComponent } from 'vue'

import isEmpty from 'lodash.isempty'
import { useStore } from '@web/store'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    as: {
      type: String,
      default: 'page' // page | modal
    }
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const history = computed(() => store.getters['router/history'])

    function goBack () {
      isEmpty(history) ? router.push({ name: 'courses' }) : router.go(-1)
    }

    return {
      goBack
    }
  }
})
