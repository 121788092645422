
import { ref, defineComponent, computed, onMounted, watch } from 'vue'
import { MqResponsive } from 'vue3-mq'
import isEmpty from 'lodash.isempty'
import AsHeaderChat from '@web/components/headers/AsHeaderChat.vue'
import AsChat from '@web/components/AsChat.vue'
import AsChatForm from '@web/components/AsMessageForm.vue'
import AsRow from '@web/components/AsRow.vue'
import AsChatRooms from '@web/components/AsRooms.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsTitle from '@web/components/AsTitle.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import AsPreloader from '@web/components/AsPreloader.vue'
import AsRenewCourse from '@web/components/AsRenewCourse.vue'
import AsRenewConsultations from '@web/components/AsRenewConsultations.vue'
import AsChatNote from '@web/components/AsChatNote.vue'
import AsChatConsultationNote from '@web/components/AsChatConsultationNote.vue'
import i18n from '@web/plugins/i18n'
import sentry from '@web/common/sentry'
import Logger from '@web/common/Logger'
import Analytics from '@web/services/Analytics/Analytics'
import Swal from 'sweetalert2'
import StreamStatus from '@web/consts/StreamStatus'
import useChat from '@web/composition/useChat'
import { performanceEnd } from '@web/common/Utils'
import { useStore } from '@web/store'
import { Stream } from '@web/store/types/modules/stream'
import FirebasePerformance from '@web/services/firebasePerformance'
import { useRouter } from 'vue-router'
import { useMeta } from '@web/common/vueMeta'
import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsEmpty from '@web/components/AsEmpty.vue'
import AsNotAvailablePage from '@web/components/AsNotAvailablePage.vue'
import AsChatSkeleton from '@web/components/AsChatSkeleton.vue'
import AsSkeleton from '@web/components/AsSkeleton.vue'
import AsRoomsSkeleton from '@web/components/AsRoomsSkeleton.vue'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default defineComponent({
  components: {
    AsRoomsSkeleton,
    AsSkeleton,
    AsChatSkeleton,
    AsNotAvailablePage,
    MqResponsive,
    AsHeaderMobile,
    AsHeaderChat,
    AsChatRooms,
    AsRow,
    AsChat,
    AsChatForm,
    AsMenu,
    AsHeaderMain,
    AsSpacer,
    AsFooter,
    AsTitle,
    AsBreadcrumb,
    AsPreloader,
    AsRenewCourse,
    AsRenewConsultations,
    AsChatNote,
    AsChatConsultationNote,
    AsEmpty
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const isOpenMenu = ref(false)

    const prevPage = computed(() => {
      const routeName: string = String(router.currentRoute.value.name) || ''
      if (['chat', 'chats'].includes(routeName)) {
        if (window.innerWidth <= 828) {
          if (routeName === 'chat') {
            return { name: 'chats' }
          }
          if (routeName === 'chats') {
            return { name: 'userSetting' }
          }
        }
        const _prevPage = store.getters['router/history']
          .find(route => !['chat', 'chats', 'userSetting'].includes(route.name))
        return _prevPage || { name: PAGE_NAME.HOME }
      }
      if (isEmpty(history)) {
        return { name: PAGE_NAME.HOME }
      }
      return undefined
    })

    const {
      loaded,
      loadedChat,
      loadedRequiredParameters,
      showChatForm,
      menu,
      chats,
      showChatContent,
      title,
      currentRoom,
      chatTitle,
      courseId,
      chatId,
      streamId,
      checkChatId,
      setPageData,
      messages
    } = useChat({ router })

    useMeta({
      title
    })

    const allLoaded = computed(() => loaded.value && loadedRequiredParameters.value)

    const rooms = computed(() => store.getters['chat/availableChats'])

    const streams = computed<Stream[]>(() => store.getters['stream/streams'])

    const stream = computed<Stream | undefined>(() => streamId.value ? store.getters['stream/getStreamById'](streamId.value) : undefined)
    const course = computed<Course | undefined>(() => stream.value ? store.getters['course/getCourseById'](stream.value.courseId) : undefined)
    const isShowChatForm = computed<boolean>(() => {
      if (!stream.value || !currentRoom.value) {
        return false
      }

      const { status } = stream.value
      return currentRoom.value.isExpertMessageEnable &&
        status !== StreamStatus.ENDED &&
        status !== StreamStatus.STOPPED &&
        showChatForm.value &&
        !store.getters['stream/isPaidConsultationsOverByStreamId'](stream.value.id)
    })
    const isNotAvailableChats = computed(() => !store.state.assets.techSupport && !showChatContent.value)

    onMounted(async () => {
      let chatLoading = performance.now()
      const startLoading = Date.now()
      loaded.value = false
      try {
        await store.dispatch('stream/fetchUserCourseStreamsCached')
        await store.dispatch('chat/fetchChats')
        if (course.value && stream.value) {
          setPageData({
            userCourseStreamId: stream.value.id,
            streamId: stream.value.streamId,
            courseId: course.value.id,
            dialogId: chatId.value,
            courseTitle: course.value.title
          })
        }
      } catch (e) {
        if (['chats', 'chat'].includes(String(router.currentRoute.value.name))) {
          sentry.captureException(e)
          Logger.error(e)
          Swal.fire({
            title: i18n.global.t('Error'),
            text: i18n.global.t('failedUploadChat'),
            icon: 'error'
          })
        }
      } finally {
        chatLoading = performanceEnd(chatLoading)
        Logger.info(`Chat loaded: ${chatLoading} ms`)
        Analytics.send({
          category: 'ux.performance',
          action: 'load_chats',
          label: chatLoading.toString()
        })
        loaded.value = true
        checkChatId()

        FirebasePerformance.record({
          traceName: 'load_chats',
          startTime: startLoading,
          options: {
            attributes: {
              userCourseStreamId: streamId.toString(),
              streamId: (stream.value?.streamId || '').toString(),
              courseId: (course.value?.id || '').toString(),
              courseTitle: course.value?.title || '',
              dialogId: chatId.value.toString()
            }
          }
        })
      }
    })

    watch(chatId, (newVal: number) => {
      if (!newVal || isNaN(newVal)) return
      loadedChat.value = false

      if (stream.value && course.value) {
        setPageData({
          userCourseStreamId: stream.value.id,
          streamId: stream.value.streamId,
          courseId: course.value.id,
          dialogId: chatId.value,
          courseTitle: course.value.title
        })
      }
    })

    watch(streamId, (streamId: number | undefined) => {
      if (!streamId) {
        return
      }
      if (!streams.value[streamId]) {
        store.dispatch('stream/fetchUserCourseStream', { id: streamId })
      }
      store.dispatch('stream/fetchStatuses', { id: streamId, expand: 'consultations,chatChannelMeta' })
    })

    return {
      // Params
      isOpenMenu,
      loaded,
      loadedChat,
      allLoaded,
      showChatForm,
      prevPage,

      // ComputedParams
      menu,
      chats,
      streams,
      title,
      currentRoom,
      messages,
      chatTitle,
      courseId,
      streamId,
      chatId,
      stream,
      isShowChatForm,
      showChatContent,
      isNotAvailableChats,
      course,
      rooms
    }
  }
})
