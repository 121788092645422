
import { defineComponent, computed } from 'vue'
import { useStore } from '@web/store'

export default defineComponent({
  props: {
    stream: {
      type: Object,
      default: () => ({}),
      require: true
    }
  },
  setup (props) {
    const store = useStore()

    const consultationCountText = computed(() => store.getters['stream/getConsultationCountTextByStreamId'](props.stream.id))
    const consultationsIsOver = computed(() => {
      const { maxConsultationCount, consultationCount } = props.stream
      const n = maxConsultationCount - consultationCount
      return n <= 0
    })

    return {
      consultationsIsOver,
      consultationCountText
    }
  }
})
