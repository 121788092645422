import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "asHeaderChat",
  "data-cy": "asHeaderChat"
}
const _hoisted_2 = { class: "asHeaderChat_title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "asHeaderChat_back",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
    }),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", {
      class: _normalizeClass(["asHeaderChat_menu", { '-hide': !_ctx.showMenu }])
    }, [
      _createVNode(_component_router_link, {
        to: '/user/setting',
        class: "asHeaderChat_setting"
      })
    ], 2)
  ]))
}