
import { defineComponent } from 'vue'

import AsSkeleton from '@web/components/AsSkeleton.vue'

export default defineComponent({
  components: {
    AsSkeleton
  }
})
