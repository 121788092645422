import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "asChat" }
const _hoisted_2 = { class: "asChat_messages_wrapper" }
const _hoisted_3 = {
  role: "group",
  ref: "refMessagesSkeleton"
}
const _hoisted_4 = {
  class: "-chatBottom",
  ref: "elChatBottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_message_skeleton = _resolveComponent("as-message-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "before"),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_as_message_skeleton),
        _createVNode(_component_as_message_skeleton, { "message-type": "own" }),
        _createVNode(_component_as_message_skeleton)
      ], 512),
      _createElementVNode("div", _hoisted_4, null, 512)
    ]),
    _renderSlot(_ctx.$slots, "after")
  ]))
}