
import { defineComponent } from 'vue'

import AsMessageSkeleton from '@web/components/AsMessageSkeleton.vue'

export default defineComponent({
  components: {
    AsMessageSkeleton
  }
})
